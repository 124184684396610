import { ImageUrlBuilderOptions } from '@sanity/image-url/lib/types/types';
import { SanityAssetRef } from '@/types/sanity';
import type { PortableTextBlock } from '@portabletext/types';

export const videoSource = (
  options: ImageUrlBuilderOptions,
  asset: SanityAssetRef,
  dir = 'files',
) => {
  const { baseUrl, projectId, dataset } = options;
  const [, filename, extension] = asset._ref.split('-');
  const baseName = [filename, extension].join('.');
  const source = [baseUrl, dir, projectId, dataset, baseName].join('/');
  return source;
};

export const blockToString = (blocks: PortableTextBlock[]): string => {
  return (
    blocks
      .find(
        (block) =>
          block._type === 'block' &&
          block.style === 'normal' &&
          block.children.some(
            (child) => child._type === 'span' && child.text !== '',
          ),
      )
      ?.children.filter((c) => c._type === 'span')
      .map((s) => s.text)
      .join('') || ''
  );
};
